import React, { useState } from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import QuizzesList from 'components/QuizList';
import SEO from 'components/seo';
import get from 'lodash.get';

export default function QuizListPage(props) {
    let [hideFooter, setHideFooter] = useState(false);
    let {
        data: { allContentfulQuiz, allContentfulProduct, contentfulPage },
    } = props;

    return (
        <Layout hideFooter={hideFooter}>
            <SEO
                description={get(
                    contentfulPage,
                    'seoListing.description.description'
                )}
                title={get(contentfulPage, 'seoListing.seoTitle')}
                slug={'quiz'}
            />
            <QuizzesList
                quizzes={allContentfulQuiz.nodes}
                allProducts={allContentfulProduct.nodes}
                setHideFooter={setHideFooter}
                startQuiz={true}
            />
        </Layout>
    );
}

export const query = graphql`
    query($locale: String) {
        allContentfulQuiz(
            filter: {
                node_locale: { eq: $locale }
                url: { eq: "/hairspray-quiz" }
            }
        ) {
            nodes {
                ...ContentfulQuizFields
            }
        }
        allContentfulProduct(filter: { node_locale: { eq: $locale } }) {
            nodes {
                id
                title
                description
                shopifyProduct {
                    handle
                }
                productItem {
                    title
                    images {
                        id
                        localFile {
                            publicURL
                            thumbnail: childImageSharp {
                                resize(width: 100, height: 100) {
                                    src
                                }
                            }
                        }
                    }
                }
            }
        }
        contentfulPage(title: { eq: "Quiz" }, node_locale: { eq: $locale }) {
            ...ContentfulPageFields
        }
    }
`;
